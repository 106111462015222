import React from "react"
import { Link } from "gatsby"


const MoneyBack = () => {
  return (

    <section>
      <div className="homeslide">
        <img src="/img/inno-reso.jpg" alt="" />
        <div className="overlayslide"></div>

        <div className="headslide">
          <h1>CAD Services Parametric solid models 2D Drafting CAD conversion outsourcing</h1>
          <h5>ENGINEERING DESIGN SERVICES PARAMETRIC SOLID MODELING AUTOMATION</h5>
          <Link to="/contactus/" className="btn btn-danger">CAD Services</Link>

        </div>
      </div>

    </section>


  )
}

export default MoneyBack
