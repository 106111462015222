import React from "react"


const FeatureSection = () => {
	return (
		<>
			<section className="world">
				<div className="container pt-4">
					<div className="row">
						<div className="col-12 col-lg-12">
							<h5 className="subheading">World className CAD services from Innoway Systems</h5><br />
							<p>Innoway Systems provides a full range of CAD services, including 3D modeling, 2D drafting, rendering, 2D to 3D conversion, and animation. To perfect the design we do provide CAE and CFD services to our customers. To have the feel of the product we develop proto- samples. Our aim is to ensure the customer receives the complete solution from us, each and every customer receives the highest quality service, at a rate well below what they would pay for in-house or domestic services. </p>
						</div>

						<div className="details col-md-8">
							<p>With highly skilled staff members available, range of expertise, we can provide the exceptional service no matter the complexity or size of your project. Each member of our team is continuously trained to use state of the art technology including up-to-date workstations and software.

Contact Innoway Systems today for all of your CAD conversion, drafting and modeling needs. Speak to our customer care directly to learn more about our services and pricing</p><br />
						</div>
						<div className="preview col-md-4">
							<div className="preview-pic tab-content">
								<div ><img src="img/image-3d-300x210-1.png" id="tab" alt="Products" width="315" height="295"></img><br /><br /><br /></div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default FeatureSection
