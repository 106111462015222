import React from "react"

const OfferBlock3 = () => {
	return (
		<section>
			<div className="container pt-4">
				<div className="row">
					<div className="col-12 col-lg-12">
						<h4 className="heading font-weight-bold">About Us</h4>
						<h5 className="subheading">INNOWAY SYSTEMS IS A COMPREHENSIVE ENGINEERING DESIGN SERVICE COMPANY</h5>
						<p>Innoway Systems provides you an end to end solution for new product development to the automotive and other industries. The services include design, development, proto-typing, tooling and documentation. Innoway Systems is capable to deliver 3D models, 2D drawings, 3D rendering, 2D to 3D conversion, and animation, etc. In every step of design work, FMEA techniques are adapted to minimize the error in the output and ensure faster delivery. </p>
					</div>
					<div className="preview col-md-4">
						<div ><img src="img/ezzdrive_1.jpg" id="tab" alt="Products" width="315" height="295"></img><br /><br /><br /></div>
					</div>
					<div className="details col-md-8">
						<p>The design outputs are supported by design calculations, in critical areas tools like FEA, CFD, etc, are used to fine tune the design outputs and optimize the investments to keep the ROI short. Automation projects are taken up from small requirements to turnkey projects. Solutions are designed according to the customer needs. We use PLC and embedded based solutions to the customers. An optimized solution is recommended after justifying the investment and end application.</p><br />
					</div>
				</div>
			</div>
		</section>
	)
}

export default OfferBlock3