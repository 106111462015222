import React from "react"
import Slider from "./slider"
import Image from "./image"
import About from "./about"
import World from "./world"
import OurProducts from "./ourproducts"
const HomePage = () => {
  return (
    <>

      <Slider />
      <About />
      <OurProducts />
      <Image />
      <World />

    </>
  )
}

export default HomePage
