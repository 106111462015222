import React from "react"
import { Link } from "gatsby"


const FAQ = () => {
  return (
    <section>
      <div className="ourp">
        <img src="/img/project-4.jpg" alt="" />
        <div className="overlay3"></div>
        <div className="container">

          <div className="row">

            <div className="col-sm">
              <div className="headprod">
                <h4>Our Products</h4>
                <p>We are the industry heads and produce the most reliable solution you are looking for.</p>
                <hr />
                <p>Foot Operated Hand free Sanitizer Dispenser Stand</p>
                <hr />
                <p>SPM Assemblies</p>
                <hr />
                <p>Pneumatic Presses</p>
                <Link to="/contactus/" className="btn btn-danger">And Much More...</Link>
              </div>
            </div>
            <div className="col-sm">
              <div className="headprod place">
                <p>The only place where you’ll get the perfect solution for all your business needs.</p>
                <Link to="/contactus/" className="btn btn-danger">See All Products</Link>
              </div>
            </div>

          </div>
        </div>

      </div>

    </section>


  )
}

export default FAQ
