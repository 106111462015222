import React from "react"
import Layout from "../components/layout"
import HomePage from "../components/home/index"
import SEO from "../components/seo"
const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="CAD Service in India - Innoway Systems"
        desc="Innoway Systems provides you an end to end solution for new product development to the automotive and other industries."
        pathname="/"
        keywords="CAD Services,mold-design,rendering"
      />
      <HomePage />
    </Layout>
  )
}

export default IndexPage
