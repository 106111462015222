import React from "react"

const MoneyBack2 = () => {
	return (
		<>
			<section className="products-card">
				<div className="container">
					<div className="row">
						<div className="col-12 col-lg-3">
							<div className="card" >
								<img src="img/a1.png" className="card-img-top" alt="..."></img>
								<div className="card-body">
									<h5 className="card-title">DESIGN</h5>
									<p className="card-text">3D modelling, 2D drawing, 2D to 3D conversion, Animation, New product design.</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-3">
							<div className="card" >
								<img src="img/a2.png" className="card-img-top" alt="..."></img>
								<div className="card-body">
									<h5 className="card-title">AUTOMATION</h5>
									<p className="card-text">Product assembly, Parts testing, Packaging, etc.</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-3">
							<div className="card" >
								<img src="img/a3.png" className="card-img-top" alt="..."></img>
								<div className="card-body">
									<h5 className="card-title">MOULD DESIGN SERVICE</h5>
									<p className="card-text">Plastic part mould design, Mould manufacturing.</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-lg-3">
							<div className="card" >
								<img src="img/a4.png" className="card-img-top" alt="..."></img>
								<div className="card-body">
									<h5 className="card-title">ENGINEERING SERVICES</h5>
									<p className="card-text">VAVE projects</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>





		</>
	)
}

export default MoneyBack2
